<template>
  <div align="center" class="footer-wrap">
      <p>©2020 - 2022 By Kudaompq</p>
      <a href="https://www.miit.gov.cn/" target="_blank" style="color: white">
        <img class="icp-icon" src="https://s1.ax1x.com/2018/09/29/ilmwIH.png" data-original="https://s1.ax1x.com/2018/09/29/ilmwIH.png">
        <span>备案号：浙ICP备2021005046号-1</span>
      </a>

  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.icp-icon {
  padding: 0 4px;
  vertical-align: text-bottom;
  max-height: 1.4em;
  width: auto;
}
</style>